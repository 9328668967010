











































import moment from "moment";
import {Vue, Component, Prop} from "vue-property-decorator";
import CouponListItemType from "./indexType";
import {delCoupon} from "@/views/Coupon/AdminCouponList/Server";
import Storage from "@/util/Storage"

@Component({name: "CouponListItem"})
export default class CouponListItem extends Vue implements CouponListItemType{
    ActiveIcon = require("@/assets/icon/Coupon/CardListBackActive.png");
    NotActiveIcon = require("@/assets/icon/Coupon/CardListBackNot.png");
    HaveIcon = require("@/assets/icon/Coupon/HaveUsed.png");
    NotHaveIcon = require("@/assets/icon/Coupon/HaveOut.png");

    handleClick(){
        let { state = "" } = this.$route.query
        if ( state == "select" ){
            Storage.SetData_?.("adminSelectCoupon",JSON.stringify(this.GetData || {}));
            this.$router.go(-1)
        }
    }

    handleToPath(){
        this.$router.push({
            name:"adminAddCoupon",
            query:{
                state:"update",
                data:JSON.stringify(this.GetData || {})
            }
        })
    }

    handleClickDel(){
        delCoupon(this.GetData.id).then(res=>res)
    }

    @Prop(Object)
    data!:any
    get GetData(){
        return this.data
    }

    get getStartTime(){
        return this.data.startDate && moment(this.data.startDate).format("YYYY-MM-DD")
    }

    get getEndTime(){
        return this.data.endDate && moment(this.data.endDate).format("YYYY-MM-DD")
    }
}
