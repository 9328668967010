




























import {Vue, Component} from "vue-property-decorator";
import AdminCouponListType from "./AdminCouponList";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import SelCouponStatus from "@/views/Coupon/AdminCouponList/components/SelCouponStatus/index.vue";
import SelType from "@/views/Coupon/AdminCouponList/components/SelType/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import ListComponent from "@/components/CouponComponent/ListComponent.vue";
import CouponListItem from "@/views/Coupon/AdminCouponList/components/Item/index.vue";
import { getCouponList } from './Server';

@Component({name: "AdminCouponList",components:{ HeadTop,SelCouponStatus,SelType,PullDownUpList,ListBottom,ListComponent,CouponListItem }})
export default class AdminCouponList extends ZoomPage implements AdminCouponListType{
    AddIcon = require("@/assets/icon/Basics/Add.png");
    PullDown = false;
    UpDownBool = false;
    StopUp = false;
    List = []
    UpData:any = {
        pageNo:1,
        pageSize:15,
        status:0,
    }

    mounted() {
        this.handleUpData();
    }

    handleGetSelStatus(e:any){
        let { state = "0" } = e;
        this.UpData.status = state;
        this.UpData.pageNo = 1;
        this.handleUpData(true)
    }

    handleGetSelType(e:any){
        let { state = 0 } = e;
        try{
            if ( state < 0 ) delete this.UpData.type;
            else this.UpData.type = state;
            this.UpData.pageNo = 1;
            this.handleUpData(true)
        }catch (e) {
            console.info("删除失败")
        }
    }

    handlePullDown(){
        this.PullDown = true;
        this.StopUp = false;
        this.UpData.pageNo = 1;
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        this.UpDownBool = true;
        this.UpData.pageNo += 1;
        this.handleUpData();
    }

    handleUpData(bool=false){
        let { List=[],UpData = {} } = this
        getCouponList(UpData).then(res=>{
            this.PullDown = false;
            this.UpDownBool = false;
            if ( res.length < UpData.pageSize )this.StopUp = true;
            if ( bool )
                this.List = res;
            else
                this.List = List.concat(res);
        })
    }

}
