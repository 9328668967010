













import {Vue, Component, Emit} from "vue-property-decorator";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";
import {SelectType} from "@/views/Coupon/AdminCouponList/Model";
import SelTypeType from "@/views/Coupon/AdminCouponList/components/SelType/indexType";

@Component({name: "SelType",components:{ ScrollViewX }})
export default class SelType extends Vue implements SelTypeType{
    TypeList = SelectType;
    SelIndex = 0;

    handleClick(index:number){
        if ( index != this.SelIndex ) {
            this.SelIndex = index;
            this.pullSelType(SelectType[index])
        }
    }

    @Emit("pullSelType")
    pullSelType(data:any){
        return data
    }
}
