










import {Vue, Component, Emit} from "vue-property-decorator";
import { SelectStatus } from "@/views/Coupon/AdminCouponList/Model";
import SelCouponStatusType from "@/views/Coupon/AdminCouponList/components/SelCouponStatus/indexType";

@Component({name: "SelCouponStatus"})
export default class SelCouponStatus extends Vue implements SelCouponStatusType{
    SelList = SelectStatus;
    SelIndex = 0;

    handleClick(index:number){
        if ( index != this.SelIndex ) {
            this.SelIndex = index;
            this.pullSelStatus(SelectStatus[index]);
        }
    }

    @Emit("pullSelStatus")
    pullSelStatus(data:any){
        return data
    }
}
